<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-2"
        v-if="active_role === 'superadmin'"
        @click="gotoAddSurvey"
      >
        Add Questioner
      </b-button>
    </div>
      <div>
        <b-card no-body>
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                  >
                    <option :value="false">
                      ASC
                    </option>
                    <option :value="true">
                      DESC
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(status)="data">
              <span class="text-capitalize">{{ data.item.status }}</span>
            </template>
            <template #cell(survey_detail)="data">
              <span class="text-centere">{{ data.item.survey_detail.length }}</span>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item v-if="active_role === 'superadmin'" @click="gotoConfigSurvey(data.item.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Config</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="active_role === 'superadmin'" @click="gotoEditSurvey(data.item.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="gotoDetailSurvey(data.item.id)">
                    <feather-icon
                      icon="ListIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Detail</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="active_role === 'superadmin'" @click="deleteData(data.item.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Delete</span>
                </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <b-modal
        v-model="modalConfig"
        title=""
        ok-title="Submit"
        ok-only
        @ok="addConfigSurvey"
      >
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="configSelected"
          name="flavour-2"
          class="demo-inline-spacing"
        >
          <b-form-checkbox v-for="(dataCorporate, idx) in corporate" :key="idx" :value="dataCorporate.value">
            {{dataCorporate.name}}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-modal>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BButton, BFormGroup, BFormCheckbox,
    BCard, BCardBody,
    BTable, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BImg,
		BDropdown, BDropdownItem, BOverlay, BModal, BFormCheckboxGroup
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
import { map } from 'postcss-rtl/lib/affected-props'

  export default {
    components: {
      BRow, BCol, BCard, BCardBody, BButton, BFormGroup, BFormCheckbox,
      BTable, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BImg,
      BDropdown, BDropdownItem, BOverlay, BModal, BFormCheckboxGroup
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        banner_status: false,
        active_role: null,
        perPage: 50,
        pageOptions: [10, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        modalConfig: false,
        modalConfigId: null,
        configSelected: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'title', label: 'Title', sortable: true },
          { key: 'survey_detail', label: 'Jumlah Form', sortable: true },
          { key: 'status', label: 'Status' },
          { key: 'actions', label: 'Actions' }
        ],
        active_role: null,
      }
    },
		computed: {
			sortOptions() {
				// Create an options list from our fields
				return this.fields
					.filter(f => f.sortable)
					.map(f => ({ text: f.label, value: f.key }))
			},
      items() {
        let survey = this.$store.state.survey.survey
        this.totalRows = survey.length
        
        return survey
      },
      corporate() {
        let corporate = this.$store.state.corporate.corporate
        return corporate.map(function (data) {
          return {
            value: data.id,
            name: data.name
          }
        })
      }
		},
    watch: {
      modalConfig(newValue, oldValue) {
        if (newValue === false) {
          this.configSelected = []
        }
      }
    },
		created() {
      this.checkAuth()
    },
		mounted() {
	    this.active_role = localStorage.getItem('role')
      console.log(this.active_role)
      this.getSurvey()
		}, 
    methods: {
      getSurvey() {
        this.$store.dispatch('survey/loadSurvey')
        this.$store.dispatch('corporate/loadCorporate')
      },
      deleteData(id) {
				this.$bvModal
					.msgBoxConfirm('Are you sure to delete this data?', {
						title: 'Delete confirmation',
						size: 'sm',
						okVariant: 'danger',
						okTitle: 'Delete',
						cancelTitle: 'Cancel',
						cancelVariant: 'outline-secondary',
						hideHeaderClose: false,
						centered: true,
					})
					.then(value => {
						if(value) {
              this.$store.dispatch('survey/deleteSurvey', id)
              .then((data) => {
                if (data.data.status) {
                  this.$bvToast.toast('Success', {
                    title: 'Delete Survey',
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-top-right',
                    autoHideDelay: 3000
                  })

                  setTimeout(() => {
                    this.$router.push({ path: '/survey' })
                  }, 3000);
                }
              })
              .catch((error) => {
                console.error(error);
                // Handle the error
              })
              this.items = []
              this.getSurvey()
						}
				})
      },
      gotoAddSurvey() {
        this.$router.push({ path: '/questioner/add' })
      },
      gotoEditSurvey(id) {
        this.$router.push({ path: '/questioner/edit/' + id })
      },
      gotoDetailSurvey(id) {
        this.$router.push({ path: '/questioner/detail/' + id })
      },
      gotoConfigSurvey(id) {
        this.$store.dispatch('survey/loadConfigSurvey', id).then((res) => {
          if (res) {
            let data = res.data.data
            data.map(value => {
              this.configSelected.push(value.corporate_id)
            })
          }
        });
        this.modalConfigId = id
        this.modalConfig = true
      },
      addConfigSurvey() {
        const selectedConfig = this.configSelected.map(value => {
          return {
            corporate_id: value
          }
        })
        const data = {
          survey_id: this.modalConfigId,
          config: selectedConfig
        }
        this.$store.dispatch('survey/addConfigSurvey', data).then((response) => {
          if (response.data.status) {
            this.$bvToast.toast('Success', {
              title: 'Update Config Survey',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-right',
              autoHideDelay: 3000
            })
          }
        })
      }
    }
  }
</script>